/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, Button, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-10vs5r3 --style3 --full pb--16" name={"uvod"} parallax={false} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 --center" anim={"2"} animS={"3"}>
              
              <Subtitle className="subtitle-box fs--24" content={"<span style=\"color: white;\">Holandská 854/1, Brno</span>"}>
              </Subtitle>

              <Title className="title-box fs--128 mt--16" style={{"maxWidth":966}} content={"<span style=\"color: white;\">A karosérie<br>bude jako nová.</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 fs--20 mt--25" content={"Kde nás najdete"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--60" name={"uvod-2  "}>
          
          <ColumnWrap className="column__flex --left el--2 pb--0 pl--0 pr--0 pt--0 flex--center" style={{"maxWidth":1280}} columns={"2"}>
            
            <ColumnWrapper className="js-anim  --anim5 --anim-s5 pb--0 pt--0" anim={"5"} animS={"5"} style={{"maxWidth":526}}>
              
              <Subtitle className="subtitle-box" content={"K dispozici 24/7"}>
              </Subtitle>

              <Title className="title-box fs--62" content={"Pro Vaše auto uděláme vše, co budeme moct."}>
              </Title>

              <Text className="text-box fs--18" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

              <Button className="btn-box btn-box--shape5" content={"Nabídka služeb"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":""}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/156/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/156/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/156/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/156/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/156/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/156/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--10" name={"sluzby"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--3 pb--0 pl--0 pr--0 pt--0 flex--center" anim={"2"} animS={"5"} style={{"maxWidth":1440}} columns={"3"} fullscreen={false}>
            
            <ColumnWrapper className="--center pb--40 pl--40 pr--40 pt--40" style={{"maxWidth":"","backgroundColor":"var(--color-blend--95)"}}>
              
              <Subtitle className="subtitle-box" content={"Od 500 Kč / hod."}>
              </Subtitle>

              <Title className="title-box fs--48" content={"Opravy a vyklepávání karosérii"}>
              </Title>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center pb--40 pl--40 pr--40 pt--40" style={{"maxWidth":"","backgroundColor":"var(--color-blend--95)"}}>
              
              <Subtitle className="subtitle-box" content={"Od 500 Kč / hod."}>
              </Subtitle>

              <Title className="title-box fs--48" content={"Výměny kapot, prahů, a blatníků"}>
              </Title>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center pb--40 pl--40 pr--40 pt--40" style={{"maxWidth":"","backgroundColor":"var(--color-blend--95)"}}>
              
              <Subtitle className="subtitle-box" content={"Od 500 Kč / hod."}>
              </Subtitle>

              <Title className="title-box fs--48" content={"Opravy poškozených autoskel"}>
              </Title>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pr--20 pt--50" name={"informace"} style={{"backgroundColor":"var(--color-custom-2)"}}>
          
          <ColumnWrap className="column__flex --right el--2 pb--0 pl--0 pr--0 pt--0 flex--center" style={{"maxWidth":1280}} columns={"2"}>
            
            <ColumnWrapper className="pb--0 pt--0" animS={"3"} style={{"maxWidth":""}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/156/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/156/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/156/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/156/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/156/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/156/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4 --anim-s5 --left pb--0 pl--0 pr--0 pt--0" anim={"4"} animS={"5"} style={{"maxWidth":526}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: white;\">Přes 15 let zkušeností</span>"}>
              </Subtitle>

              <Title className="title-box fs--62" content={"<span style=\"color: white;\">Profesionální mechanici s léty zkušeností<br></span>"}>
              </Title>

              <Text className="text-box fs--18" content={"<span style=\"color: white;\">Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text.</span>"}>
              </Text>

              <Button className="btn-box btn-box--shape5" content={"Nabídka služeb"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1rkryxn pb--80 pt--80" name={"kontakt"} parallax={false} css={css`
        
    background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/156/fullscreen-2_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2" anim={"7"} animS={"2"}>
              
              <Title className="title-box fs--72 mt--16" style={{"maxWidth":966}} content={"<span style=\"color: white;\">Máte dotaz?</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shape5 fs--22" content={"Kontaktovat"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Autoservis Dostál</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: white;\">Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">O nás</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: white;\">Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: white;\">+420 797 811 2X0<br>Holandská 854/1, Brno</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: white;\">IČ:: 12345678<br>Vytvořeno přes <a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}